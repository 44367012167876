<template>
  <div class="sell">
    <h1>This is a selling page</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
